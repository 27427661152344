import { createPermissionChecker, prepareIotRoutes } from '../../helper';
import { LOCK_VENDORS } from '@/utils/constants/iot/locks';

const lockRoutes = [
    {
        name: 'iot.locks.bmx',
        path: '/iot/locks/butterflymx/:lockId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/butterflymx/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock details',
            title: 'lock details',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('BMX_DEVICE_READ_BY_ID'),
        },
    },
    {
        name: 'iot.locks.bmx.access-history',
        path: '/iot/locks/butterflymx/:lockId/access-history',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/AccessHistory.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'access history',
            title: 'access history',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('BMX_DEVICE_READ_BY_ID'),
            lockType: LOCK_VENDORS.BMX,
        },
    },
];

const managementRoutes = [
    {
        name: 'iot.locks.lock-management.butterflymx',
        path: '/iot/locks/management/butterflymx',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/butterflymx/management/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'butterflymx',
            title: 'lock installation',
            flowStartsWith: 'iot.locks.lock-management.butterflymx',
            permissionChecker: createPermissionChecker('BMX_BUILDING_READ_LIST'),
        },
    },
    {
        name: 'iot.locks.lock-management.butterflymx.import-buildings',
        path: '/iot/locks/management/butterflymx/import-buildings',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/butterflymx/management/ImportBuildings.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'import buildings',
            title: 'import buildings',
            flowStartsWith: 'iot.locks.lock-management.butterflymx.import-buildings',
            permissionChecker: createPermissionChecker('BMX_BUILDING_IMPORT'),
        },
    },
    {
        name: 'iot.locks.lock-management.butterflymx.select-location',
        path: '/iot/locks/management/butterflymx/select-location/:intercomId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/butterflymx/management/SelectLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'select location',
            title: 'select location',
            flowStartsWith: 'iot.locks.lock-management.butterflymx.select-location',
            permissionChecker: createPermissionChecker('BMX_DEVICE_CHANGE_LOCATION'),
        },
    },
    {
        name: 'iot.locks.lock-management.butterflymx.select-lock',
        path: '/iot/locks/management/butterflymx/select-lock/:intercomId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/butterflymx/management/SelectLock.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'connect lock',
            title: 'connect lock',
            flowStartsWith: 'iot.locks.lock-management.butterflymx.select-lock',
            permissionChecker: createPermissionChecker('BMX_DEVICE_UPDATE'),
        },
    },
];

export default prepareIotRoutes([...lockRoutes, ...managementRoutes]);
